import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import * as React from "react";
import { useCookies } from "react-cookie";
import { Route, Switch, useHistory } from "react-router-dom";
import { io } from "socket.io-client";
import Fallback from "./components/Fallback";
import { API_BASE_URL } from "./constants";
import Header from "./components/Header";
const AddFriendPage = React.lazy(
  () => import("./pages/AddFriendPage/AddFriendPage")
);
const ChatPage = React.lazy(() => import("./pages/ChatPage/ChatPage"));
const ChatsPage = React.lazy(() => import("./pages/ChatsPage/ChatsPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage/LoginPage"));
const SignupPage = React.lazy(() => import("./pages/SignupPage/SignupPage"));

const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
  },
});
const lightTheme = createMuiTheme({
  palette: {
    type: "light",
  },
});

const socket = io(`${API_BASE_URL.replace("http", "ws")}`, {
  reconnectionDelayMax: 10000,
  withCredentials: true,
});

function App() {
  const [darkMode, setDarkMode] = React.useState(darkThemeMq.matches);

  const [cookies] = useCookies(["name"]);

  darkThemeMq.addEventListener("change", (evt) => {
    setDarkMode(evt.matches);
  });

  const history = useHistory();

  if (
    !cookies.name &&
    !["/", "/login", "/signup"].includes(history.location.pathname)
  ) {
    history.push("/login");
  }

  React.useEffect(() => {
    const listener = (
      e: string,
      message: { contents: string; sender: string }
    ) => {
      if (message.sender !== cookies.name && "Notification" in window) {
        new Notification(`New Message from ${message.sender}`, {
          body: message.contents,
        });
      }
    };
    socket.onAny(listener);
    return () => {
      socket.offAny(listener);
    };
  }, [cookies.name]);
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />

      <Header />
      <Switch>
        <Route path="/login">
          <React.Suspense fallback={<Fallback />}>
            <LoginPage />
          </React.Suspense>
        </Route>
        <Route path="/signup">
          <React.Suspense fallback={<Fallback />}>
            <SignupPage />
          </React.Suspense>
        </Route>
        <Route path="/addFriend">
          <React.Suspense fallback={<Fallback />}>
            <AddFriendPage />
          </React.Suspense>
        </Route>
        <Route path="/chats">
          <React.Suspense fallback={<Fallback />}>
            <ChatsPage />
          </React.Suspense>
        </Route>
        <Route path="/chat/:id">
          <React.Suspense fallback={<Fallback />}>
            <ChatPage socket={socket} />
          </React.Suspense>
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

export default App;
