import { Paper, Typography, LinearProgress } from "@material-ui/core";

const Fallback = () => {
  return (
    <Paper className="paper" style={{ textAlign: "center" }}>
      <Typography variant="h3">Loading...</Typography>
      <LinearProgress />
    </Paper>
  );
};

export default Fallback;
