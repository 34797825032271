import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import * as React from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
    null
  );
  const [cookies, , removeCookie] = useCookies(["name"]);

  const open = Boolean(anchorEl);
  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <Typography style={{ flexGrow: 1 }} variant="h3">
          Secure Chat
        </Typography>
        {cookies.name ? (
          <>
            <Link to="/addFriend">
              <Button variant="outlined" style={{ float: "right" }}>
                Add Friend
              </Button>
            </Link>
            <Link to="/chats" style={{ marginLeft: "10px" }}>
              <Button variant="outlined" style={{ float: "right" }}>
                Chats
              </Button>
            </Link>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => setAnchorEl(null)}>Profile</MenuItem>
              <MenuItem
                onClick={() => {
                  removeCookie("name");
                  setAnchorEl(null);
                }}
              >
                Sign Out
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Link to="/signup">
              <Button variant="outlined">Sign up</Button>
            </Link>
            <Link to="/login" style={{ marginLeft: "10px" }}>
              <Button variant="outlined">Login</Button>
            </Link>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
